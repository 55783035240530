/* eslint-disable */
import React, { Component } from 'react';
import { FILE_API_URL } from 'config/network';
import NewKpisLatestPhotosModal from './newKpisLatestPhotosModal';

let dataKpi = {
  loaded: false,
  photos: [],
  showModalCarousel: false,
  indexPhoto: 0,
};

export default class LatestPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = dataKpi;
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.props.dataExtraKpi.forEach((photo, index) => {
      if (index === 0) {
        dataKpi.photos = [];
      }
      dataKpi.photos.push({
        ...photo,
        url: `${FILE_API_URL}/${photo.url}?token=${this.props.token}`,
      });
    });
    dataKpi.loaded = true;
    console.log(dataKpi.photos);
    this.setState(dataKpi);
  };

  toggleShowModalCarousel = idx => {
    this.setState({ indexPhoto: idx });
    this.setState(prevState => ({
      showModalCarousel: !prevState.showModalCarousel,
      indexPhoto: idx,
    }));
  };

  render() {
    return (
      this.state.loaded && (
        <>
          <div class="ui card" style={{ width: '100%', height: '292px' }}>
            <div class="content">
              <div class="header">Latest photos</div>
              {this.state.photos.length <= 0 && (
                <div class="meta">No photos for the selected period</div>
              )}
            </div>
            {this.state.photos.length > 0 && (
              <>
                <div class="ui equal width center aligned padded grid">
                  <div class="row">
                    <div
                      class="column"
                      style={{ padding: '0px', borderRight: '2px solid white' }}
                    >
                      {this.state.photos.length >= 1 && (
                        <img
                          onClick={() => this.toggleShowModalCarousel(0)}
                          src={this.state.photos[0].url}
                          style={{ height: '156px' }}
                        />
                      )}
                    </div>
                    <div
                      class="column"
                      style={{ padding: '0px', borderRight: '2px solid white' }}
                    >
                      {this.state.photos.length >= 2 && (
                        <img
                          onClick={() => this.toggleShowModalCarousel(1)}
                          src={this.state.photos[1].url}
                          style={{ height: '156px' }}
                        />
                      )}
                    </div>

                    <div class="column" style={{ padding: '0px' }}>
                      {this.state.photos.length >= 3 && (
                        <img
                          onClick={() => this.toggleShowModalCarousel(2)}
                          src={this.state.photos[2].url}
                          style={{ height: '156px' }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div class="extra content">
                  <button
                    onClick={() => this.toggleShowModalCarousel(0)}
                    class="ui button"
                  >
                    View all photos
                  </button>
                </div>
              </>
            )}
          </div>
          <NewKpisLatestPhotosModal
            modalTitle={'Latest photos'}
            isOpen={this.state.showModalCarousel}
            closeModal={this.toggleShowModalCarousel}
            photos={this.state.photos}
            idxPhoto={this.state.indexPhoto}
          />
        </>
      )
    );
  }
}
