/* eslint-disable */
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

let dataKpi = {
  chart1: {
    series: [1000, 200],
    options: {
      chart: {
        type: 'donut',
      },
      fill: {
        colors: ['#db931e', '#db1e1e'],
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        fillSeriesColor: false,
      },
      colors: ['#db931e', '#db1e1e'],
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
      labels: ['Hours on routine', 'Hours on Criticals'],
      grid: {
        padding: {
          bottom: -80,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    detail: {
      total: 1200,
      routines: 1000,
      criticals: 200,
    },
  },
  chart2: {
    series: [550, 120],
    options: {
      chart: {
        type: 'donut',
      },
      fill: {
        colors: ['#db931e', '#db1e1e'],
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        fillSeriesColor: false,
      },
      colors: ['#db931e', '#db1e1e'],
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
      grid: {
        padding: {
          bottom: -80,
        },
      },
      labels: ['Hours on routine', 'Hours on Criticals'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
    detail: {
      total: 10000,
      routines: 7000,
      criticals: 3000,
    },
  },
};

export default class HoursAndPriceMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(JSON.stringify(dataKpi));
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    dataKpi.chart1.series = this.props.dataExtraKpi.chart1.series;
    dataKpi.chart1.detail = this.props.dataExtraKpi.chart1.detail;
    dataKpi.chart2.series = this.props.dataExtraKpi.chart2.series;
    dataKpi.chart2.detail = this.props.dataExtraKpi.chart2.detail;
    this.setState(dataKpi);
  };

  render() {
    return (
      <>
        <div className="kpiContent">
          <div class="card">
            <div class="content">
              <div class="header">
                <h4>{this.props.dataExtraKpi.extra.title}</h4>
              </div>
              <div class="meta">{this.props.dataExtraKpi.extra.subtitle}</div>
              <div class="description" style={{ paddingTop: '20px' }}>
                <div class="ui two column grid">
                  <div
                    class="column"
                    style={{
                      borderRight: '2px solid #545252',
                    }}
                  >
                    <div class="header" style={{ textAlign: 'center' }}>
                      <div class="ui grey large label">
                        Hours of maintenance
                      </div>
                    </div>
                    <Chart
                      options={this.state.chart1.options}
                      series={this.state.chart1.series}
                      type="donut"
                      height={250}
                      width={300}
                    />
                    <div
                      class="ais ui statistics centered"
                      style={{
                        textAlign: 'center',
                        marginTop: '-13px',
                        marginLeft: '10px',
                      }}
                    >
                      <div class="column grey statistic">
                        <div
                          class="value"
                          style={{ 'font-size': '2rem !important' }}
                        >
                          {this.state.chart1.detail.total}
                        </div>
                        <div class="label">Total</div>
                      </div>
                      <div class="column orange statistic">
                        <div class="value">
                          {this.state.chart1.detail.routines}
                        </div>
                        <div class="label">Routines</div>
                      </div>
                      <div class="column red statistic">
                        <div class="value">
                          {this.state.chart1.detail.criticals}
                        </div>
                        <div class="label">Criticals</div>
                      </div>
                    </div>
                  </div>
                  <div class="column">
                    <div class="header" style={{ textAlign: 'center' }}>
                      <div class="ui grey large label">Maintenance Cost</div>
                    </div>
                    <Chart
                      options={this.state.chart2.options}
                      series={this.state.chart2.series}
                      type="donut"
                      height={250}
                      width={300}
                    />
                    <div
                      class="ais ui statistics centered"
                      style={{
                        textAlign: 'center',
                        marginTop: '-13px',
                        marginLeft: '10px',
                      }}
                    >
                      <div class="column grey statistic">
                        <div
                          class="value"
                          style={{ 'font-size': '2rem !important' }}
                        >
                          {this.state.chart2.detail.total}
                        </div>
                        <div class="label">Total</div>
                      </div>
                      <div class="column orange statistic">
                        <div class="value">
                          {this.state.chart2.detail.routines}
                        </div>
                        <div class="label">Routines</div>
                      </div>
                      <div class="column red statistic">
                        <div class="value">
                          {this.state.chart2.detail.criticals}
                        </div>
                        <div class="label">Criticals</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
